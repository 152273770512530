import React from "react"
import { Helmet } from "react-helmet"

const Head = props => {

  return (
    <Helmet
    >
      <title>{ `${props.pageTitle} | Vincent's 🌌` }</title>
      <meta property="og:title" content={ props.pageTitle } />
      <meta property="og:image" content={ props.coverImage } />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@vincent_yc_li" />
      <meta name="twitter:title" content={ props.pageTitle } />
      <meta name="twitter:image" content={ props.coverImage } />

    </Helmet>
  )
}

export default Head