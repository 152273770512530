import React, { useState } from "react"
import { Link } from "gatsby"
import classes from "./Header.module.styl"
import { isMobile } from "react-device-detect";


const Header = (props) => {
  const [ isDropdown, setIsDropdown ] = useState(false)

  let navList = (
    <nav className={ classes.dropdownContainer }>
      <Link to="/notes">Notes</Link>
      {/* <Link to="/essays">Essays</Link> */ }
      {/* <Link to="/logs">Logs</Link> */ }
      <Link to="/library">Library</Link>
      <Link to="/manual">Manual</Link>
      <Link to="/about">About</Link>
    </nav>
  )
  let mobileNav;

  if (isMobile) {
    mobileNav = (
      <div onClick={ () => setIsDropdown(!isDropdown) } className={ classes.menuIcon }>
        +
        { isDropdown ? navList : null }
      </div>
    )
  }

  return (
    <div className={ classes.container }>
      { props.children }
      { isMobile ? mobileNav : navList }
    </div>
  )
}

export default Header
