import React from "react"
import classes from "./Layout.module.styl"

import Header from "../components/Header"
import Head from "./Head.js"
import { useBreadcrumb } from 'gatsby-plugin-breadcrumb'
import CustomBreadcrumb from "../components/CustomBreadcrumb"

const Layout = props => {

  const { crumbs } = useBreadcrumb({
    location: props.location,
    crumbLabel: props.pageTitle,
    crumbSeparator: ' / ',
  })

  return (
    <div>
      <Head pageTitle={ props.pageTitle } coverImage={ props.coverImage } />
      <Header>
        <CustomBreadcrumb crumbs={ crumbs } />
      </Header>
      <div className={ classes.contentContainer }>
        { props.children }
      </div>
    </div>
  )
}

export default Layout
