import React, { useState } from "react"
import { Link } from 'gatsby'
import classes from "./CustomBreadcrumb.module.styl"
import { isMobile } from "react-device-detect";

const CustomBreadcrumb = ({ crumbs }) => {
  const [isMenu, setIsMenu] = useState(false)

  const crumbSeparator = '/'

  let breadcrumbsArr = []
  // crumbs that are in the menu
  let menuCrumbs = []
  let dropdownEl;

  const crumbsMenuHandler = () => setIsMenu(!isMenu)

  const shortenLabel = (label, context) => {

    if (!isMobile) {
      if (label.length > 20) return `${label.substring(0, 20)}...`
    } else {
      if (context === 'dropdown')
        if (label.length > 30) {
          return `${label.substring(0, 30)}...`
        } else {
          return label
        }

      if (label.length > 5) return `${label.substring(0, 5)}...`
    }
    return label
  }

  if (crumbs.length < 4) {
    for (let i = 0; i < crumbs.length; i++) {

      let label;
      if (i === 0) label = crumbs[i].crumbLabel
      if (i !== 0) label = shortenLabel(crumbs[i].crumbLabel, 'navBar')

      if (i < crumbs.length - 1) {
        breadcrumbsArr.push(
          <span key={ crumbs[i].pathname }>
            <Link to={ crumbs[i].pathname }>{ label }</Link>
            <span className={ classes.crumbSeparator }>{ crumbSeparator }</span>
          </span>
        )
      } else {
        // remove crumbSeparator for last in list
        breadcrumbsArr.push(
          <span key={ crumbs[i].pathname }>
            <Link to={ crumbs[i].pathname }>{ label }</Link>
          </span>
        )
      }
    }

  } else {

    if (isMenu) {
      for (let i = 1; i < crumbs.length - 1; i++) {
        const label = shortenLabel(crumbs[i].crumbLabel, 'dropdown')
        menuCrumbs.push(
          <Link key={ crumbs[i].pathname } to={ crumbs[i].pathname }>{ label }</Link>
        )
      }

      dropdownEl = (
        <div key={ 'dropdown_el' } className={ classes.dropdownContainer }>
          { menuCrumbs }
        </div>
      )
    }

    breadcrumbsArr.push(
      <span key={ crumbs[0].pathname }>
        <Link to={ crumbs[0].pathname }>{ crumbs[0].crumbLabel }</Link>
        <span className={ classes.crumbSeparator }>{ crumbSeparator }</span>
      </span>,

      <span className={ classes.menuContainer } key={ 'concat-span' }>
        <span onClick={ crumbsMenuHandler } className={ classes.concat }>
          ...
        </span>
        <span className={ classes.crumbSeparator }>{ crumbSeparator }</span>
        { isMobile ? null : dropdownEl }
      </span>,

      <span key={ crumbs[crumbs.length - 1].pathname }>
        <Link to={ crumbs[crumbs.length - 1].pathname }>{ shortenLabel(crumbs[crumbs.length - 1].crumbLabel, 'navBar') }</Link>
      </span>
    )

    if (isMobile) breadcrumbsArr.push(dropdownEl)

  }


  return (
    <nav className={ classes.breadcrumbContainer }>
      {breadcrumbsArr }
    </nav>
  )
}

export default CustomBreadcrumb